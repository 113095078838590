import { DEPLOY_ENV } from '@config';

export function isValidSearchParamTriggerVal(
  searchParamVal: string | null | undefined,
) {
  if (!searchParamVal) {
    return false;
  }

  return (
    searchParamVal === '1' ||
    searchParamVal === 'true' ||
    searchParamVal === 'enabled'
  );
}

export function isValidTestSearchParamTriggerVal(
  searchParamVal: string | null | undefined,
) {
  if (DEPLOY_ENV === 'prod') {
    return false;
  }

  return isValidSearchParamTriggerVal(searchParamVal);
}
