'use client';

import type { ReactNode } from 'react';
import { useModal } from '@contexts/global-modal/global-modal.provider';
import { PreviewModeModal } from './preview-mode-modal/preview-mode-modal.module';
import { PreviewModeView } from './preview-mode.view';

type PreviewModeProps = {
  children: ReactNode;
  isPreviewModeActive: boolean;
};

export function PreviewMode({
  children,
  isPreviewModeActive,
}: PreviewModeProps) {
  const { openModal } = useModal();

  if (!isPreviewModeActive) {
    return children;
  }

  return (
    <>
      <PreviewModeView
        handleOnClick={() =>
          openModal({
            modalId: 'feature-flags-override-modal',
            title: 'Preview options',
            children: <PreviewModeModal />,
          })
        }
      />
      {children}
    </>
  );
}
