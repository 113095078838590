'use client';

import React, { useContext, useState } from 'react';
import type { BaseErrorInlineMessageType } from '@components/error-inline-message/error-inline-message.types';
import { GlobalErrorContext } from './global-error.context';
import {
  errorDescriptions,
  ErrorIds,
  GlobalErrorProviderProps,
} from './global-error.types';

export function useGlobalError() {
  return useContext(GlobalErrorContext);
}

export function GlobalErrorProvider({ children }: GlobalErrorProviderProps) {
  const [errorIdList, setErrorIdList] = useState<ErrorIds[]>([]);

  function setGlobalError(errorIds: ErrorIds[]) {
    setErrorIdList((prevErrorIds) => {
      const newErrorIds = errorIds.filter(
        (errorId) => !prevErrorIds.includes(errorId),
      );
      return newErrorIds;
    });
  }

  function clearGlobalError() {
    setErrorIdList([]);
  }

  function hasGlobalError(errorIds: ErrorIds[]) {
    let hasError = false;
    let dictionaryItem: BaseErrorInlineMessageType = {
      title: '',
      description: '',
      navigationTitle: '',
    };

    errorIds.forEach((errorId) => {
      if (errorIdList.includes(errorId)) {
        hasError = true;
        dictionaryItem = getErrorDictionaryItem(errorId);
      }
    });

    return { hasError, dictionaryItem };
  }

  function getErrorDictionaryItem(errorId: ErrorIds) {
    return errorDescriptions[errorId];
  }

  return (
    <GlobalErrorContext.Provider
      value={{ setGlobalError, clearGlobalError, hasGlobalError }}
    >
      {children}
    </GlobalErrorContext.Provider>
  );
}
