import { PREVIEW_QUERY_PARAMS } from '@config';
import { usePathname, useSearchParams } from 'next/navigation';

let remainingQueryParams = '';

type PreviewModeModalCtaProps = {
  changeFeatureFlags: boolean;
  changeShowDictionaries: boolean;
  changeShowErrorReporting: boolean;
  changeShowSources: boolean;
  overrideFlagsQueryParam: string | null;
  showDictionaries: boolean;
  showErrorReporting: boolean;
  showSources: boolean;
  useProdData: boolean;
  overrideAvailableAt: string | null;
  changeOverrideAvailableAt: boolean;
};

export function PreviewModeModalCta({
  changeFeatureFlags,
  changeShowDictionaries,
  changeShowErrorReporting,
  changeShowSources,
  overrideFlagsQueryParam,
  showDictionaries,
  showErrorReporting,
  showSources,
  useProdData,
  changeOverrideAvailableAt,
  overrideAvailableAt,
}: PreviewModeModalCtaProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  if (
    !changeFeatureFlags &&
    !changeShowDictionaries &&
    !changeShowSources &&
    !useProdData &&
    !changeShowErrorReporting &&
    !changeOverrideAvailableAt
  ) {
    return null;
  }

  function handleRemainingQueryParams(key: string, value: string) {
    // No value added yet
    if (remainingQueryParams === '') {
      remainingQueryParams = `${key}=${value}`;
    }
    // Value already added
    else {
      remainingQueryParams += `&${key}=${value}`;
    }
  }

  // Pick all query params present in the URL except the ones we have new changes
  if (!remainingQueryParams) {
    const testQueryParamsValues: Array<string> = Object.values(
      PREVIEW_QUERY_PARAMS,
    ).filter((queryParam) => queryParam !== PREVIEW_QUERY_PARAMS.preview);

    searchParams.forEach((value, key) => {
      if (testQueryParamsValues.includes(key)) {
        return;
      }
      handleRemainingQueryParams(key, value);
    });
  }

  const showDictionariesQueryParam =
    showDictionaries &&
    `${PREVIEW_QUERY_PARAMS.dictionaries}=${showDictionaries}`;
  const showSourcesQueryParam =
    showSources && `${PREVIEW_QUERY_PARAMS.showSources}=${showSources}`;
  const useProdDataQueryParam =
    useProdData && `${PREVIEW_QUERY_PARAMS.useProdData}=${useProdData}`;
  const showErrorReportingQueryParam =
    showErrorReporting &&
    `${PREVIEW_QUERY_PARAMS.errorReporting}=${showErrorReporting}`;
  const overrideAvailableAtQueryparam =
    overrideAvailableAt &&
    `${PREVIEW_QUERY_PARAMS.overrideAvailableAt}=${overrideAvailableAt}`;

  const chainedQueryParams = [
    overrideFlagsQueryParam,
    showDictionariesQueryParam,
    showSourcesQueryParam,
    showErrorReportingQueryParam,
    remainingQueryParams,
    useProdDataQueryParam,
    overrideAvailableAtQueryparam,
  ]
    .filter(Boolean)
    .join('&');

  return (
    <a
      className="flex button-filled my-16 mx-auto w-fit"
      data-color="accent"
      href={`${pathname}?${chainedQueryParams}`}
    >
      Save changes
    </a>
  );
}
