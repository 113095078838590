import { ReactNode } from 'react';
import type { BaseErrorInlineMessageType } from '@components/error-inline-message/error-inline-message.types';
import { createDictionaryItemsCanonicalNameRef } from '@utils/dictionaries';

export enum ErrorIds {
  GENERIC_OFFLINE = 'GENERIC_OFFLINE',
  OFFLINE_MILEAGE = 'OFFLINE_MILEAGE',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

export const errorDescriptions: {
  INVALID_PHONE_NUMBER: BaseErrorInlineMessageType;
  GENERIC_OFFLINE: BaseErrorInlineMessageType;
  OFFLINE_MILEAGE: BaseErrorInlineMessageType;
} = {
  INVALID_PHONE_NUMBER: createDictionaryItemsCanonicalNameRef({
    title: 'Insurance.Error.invalidPhone',
    description: 'Insurance.Error.invalidPhoneDescription',
    navigationTitle: 'Insurance.Error.contact',
  }),
  GENERIC_OFFLINE: createDictionaryItemsCanonicalNameRef({
    description: 'Insurance.Error.offline',
  }),
  OFFLINE_MILEAGE: createDictionaryItemsCanonicalNameRef({
    description: 'Insurance.Error.offlineMileage',
  }),
};

export type GlobalError = {
  setGlobalError: (errorIds: ErrorIds[]) => void;
  clearGlobalError: () => void;
  hasGlobalError: (errorIds: ErrorIds[]) => {
    hasError: boolean;
    dictionaryItem: BaseErrorInlineMessageType;
  };
};

export type GlobalErrorProviderProps = {
  children: ReactNode;
};
