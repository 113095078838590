/**
 * Returns needed variables for client-side RUM initialization.
 *
 * These environment variables are set by QRE for each application and do not need to
 * be added manually, except for `BUILD_SOURCEVERSION`
 */
export function getElasticApmVariables() {
  return {
    elasticEnvironment: process.env.ELASTIC_APM_ENVIRONMENT,
    elasticLogLevel: process.env.ELASTIC_APM_LOG_LEVEL,
    elasticServerUrl: process.env.ELASTIC_APM_SERVER_URL,
    elasticServiceName: process.env.ELASTIC_APM_SERVICE_NAME,
    elasticServiceVersion: process.env.BUILD_SOURCEVERSION,
    ...(process.env.NODE_ENV === 'development' && {
      elasticServiceApiKey: process.env.ELASTIC_APM_API_KEY,
    }),
  };
}
