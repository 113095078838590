import styles from './preview-mode.module.css';

type PreviewModeViewProps = {
  handleOnClick: () => void;
};

export function PreviewModeView({ handleOnClick }: PreviewModeViewProps) {
  return (
    <div
      className={`bg-surface-accent-blue flex-row items-center justify-center ${styles['preview-container']}`}
    >
      <p className="text-always-white">Now viewing in Preview mode</p>
      <button
        className="link-inline button-text text-always-white px-24"
        onClick={handleOnClick}
        type="button"
      >
        Show preview options
      </button>
    </div>
  );
}
