'use client';

import { createContext } from 'react';

export type ClientSideRuntimeVariables = {
  BASE_URL: string;
  DEPLOY_ENV: string;
};

export const ClientSideRuntimeVariablesContext =
  createContext<ClientSideRuntimeVariables>({
    BASE_URL: '',
    DEPLOY_ENV: '',
  });
