'use client';

import React, { useContext, useState } from 'react';
import { GlobalLoadingContext } from './global-loading.context';
import { GlobalLoadingProviderProps, LoadingIds } from './global-loading.types';

export function useGlobalLoading() {
  return useContext(GlobalLoadingContext);
}

export function GlobalLoadingProvider({
  children,
}: GlobalLoadingProviderProps) {
  const [loadingIds, setLoadingIds] = useState<LoadingIds[]>([]);

  function setGlobalLoading(loadingId: LoadingIds) {
    setLoadingIds((prevLoadingIds) => [...prevLoadingIds, loadingId]);
  }

  function clearGlobalLoading(loadingId: LoadingIds) {
    setLoadingIds((prevLoadingIds) =>
      prevLoadingIds.filter((id) => id !== loadingId),
    );
  }

  const hasGlobalLoading = (loadingId: LoadingIds) =>
    loadingIds.includes(loadingId);

  return (
    <GlobalLoadingContext.Provider
      value={{
        setGlobalLoading,
        clearGlobalLoading,
        hasGlobalLoading,
      }}
    >
      {children}
    </GlobalLoadingContext.Provider>
  );
}
