'use client';

import { createContext } from 'react';
import { GlobalLoading } from './global-loading.types';

export const GlobalLoadingContext = createContext<GlobalLoading>({
  setGlobalLoading: () => {},
  clearGlobalLoading: () => {},
  hasGlobalLoading: () => false,
});
