import { useContext, useEffect } from 'react';
import { handleOverrideFeatureFlagsToQueryStr } from '@components/preview-mode/preview-mode-modal/preview-mode-modal.utils';
import type {
  FeatureFlagKey,
  GlobalFeatureFlagKey,
  InternalFeatureFlagKey,
  PartialGlobalFeatureFlags,
  PartialInternalFeatureFlags,
} from '@contexts/feature-flags';
import { FeatureFlagsContext } from '@contexts/feature-flags/feature-flags.context';
import {
  AccordionDetails,
  AccordionSummary,
} from '@volvo-cars/react-accordion';
import { useSearchParams } from 'next/navigation';
import { OverrideFeatureFlagsInput } from './override-feature-flags-input/override-feature-flags-input.module';

type OverrideFeatureFlagsProps = {
  updatedFeatureFlags: PartialInternalFeatureFlags;
  updatedGlobalFeatureFlags: PartialGlobalFeatureFlags;
  setOverrideFlagsQueryParam: (overrideFlagsQueryParamStr: string) => void;
  setUpdatedFeatureFlags: (
    updatedFeatureFlag: PartialInternalFeatureFlags,
  ) => void;
  setUpdatedGlobalFeatureFlags: (
    updatedGlobalFeatureFlag: PartialGlobalFeatureFlags,
  ) => void;
};

export function OverrideFeatureFlags({
  updatedFeatureFlags,
  updatedGlobalFeatureFlags,
  setOverrideFlagsQueryParam,
  setUpdatedFeatureFlags,
  setUpdatedGlobalFeatureFlags,
}: OverrideFeatureFlagsProps) {
  const searchParams = useSearchParams();
  const overriddenFlagsQueryParamStr = handleOverrideFeatureFlagsToQueryStr({
    searchParams,
    updatedFeatureFlags,
    updatedGlobalFeatureFlags,
  });

  useEffect(() => {
    if (overriddenFlagsQueryParamStr) {
      setOverrideFlagsQueryParam(overriddenFlagsQueryParamStr);
    }
  }, [overriddenFlagsQueryParamStr, setOverrideFlagsQueryParam]);

  const allFeatureFlags = useContext(FeatureFlagsContext);
  if (!allFeatureFlags.featureFlags && !allFeatureFlags.globalFeatureFlags) {
    return null;
  }

  function handleFeatureFlagChange(key: FeatureFlagKey, value: any) {
    if (!allFeatureFlags.featureFlags || !allFeatureFlags.globalFeatureFlags) {
      return null;
    }

    if (key in allFeatureFlags.featureFlags) {
      setUpdatedFeatureFlags({
        ...updatedFeatureFlags,
        [key]: value,
      });
    }

    if (
      allFeatureFlags.globalFeatureFlags &&
      key in allFeatureFlags.globalFeatureFlags
    ) {
      setUpdatedGlobalFeatureFlags({
        ...updatedGlobalFeatureFlags,
        [key]: value,
      });
    }
  }

  const { featureFlags, globalFeatureFlags } = allFeatureFlags;

  const featureFlagKeyValuePairs = Object.entries(featureFlags || {}) as Array<
    [InternalFeatureFlagKey, any]
  >;

  const globalFeatureFlagKeyValuePairs = Object.entries(
    globalFeatureFlags || {},
  ) as Array<[GlobalFeatureFlagKey, any]>;

  return (
    <>
      {featureFlags && (
        <AccordionDetails className="current:border-primary px-16 pb-16">
          <AccordionSummary className="font-medium pt-0">
            Storefront feature flags
          </AccordionSummary>
          {featureFlagKeyValuePairs.map((flag) => (
            <OverrideFeatureFlagsInput
              key={flag[0]}
              featureFlagKey={flag[0]}
              featureFlagValue={flag[1]}
              onChange={handleFeatureFlagChange}
            />
          ))}
        </AccordionDetails>
      )}

      {globalFeatureFlags && (
        <AccordionDetails className="current:border-primary px-16 pb-16">
          <AccordionSummary className="font-medium pt-0">
            Global Dotcom feature flags
          </AccordionSummary>
          {globalFeatureFlagKeyValuePairs.map((flag) => (
            <OverrideFeatureFlagsInput
              key={flag[0]}
              featureFlagKey={flag[0]}
              featureFlagValue={flag[1]}
              onChange={handleFeatureFlagChange}
            />
          ))}
        </AccordionDetails>
      )}
    </>
  );
}
