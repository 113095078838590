'use client';

import { createContext } from 'react';
import type { GlobalError } from './global-error.types';

export const GlobalErrorContext = createContext<GlobalError>({
  setGlobalError: () => {},
  clearGlobalError: () => {},
  hasGlobalError: () => ({
    hasError: false,
    dictionaryItem: {
      title: '',
      description: '',
      navigationTitle: '',
    },
  }),
});
