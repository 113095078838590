import { useState } from 'react';
import type { FeatureFlagKey } from '@contexts/feature-flags';
import { Checkbox, TextArea, TextInput } from '@volvo-cars/react-forms';

type OverrideFeatureFlagsInputProps = {
  featureFlagKey: FeatureFlagKey;
  featureFlagValue: any;
  onChange: (key: FeatureFlagKey, value: any) => void;
};

export function OverrideFeatureFlagsInput({
  featureFlagKey,
  featureFlagValue,
  onChange,
}: OverrideFeatureFlagsInputProps) {
  const formatFeatureFlagValue =
    typeof featureFlagValue === 'object'
      ? JSON.stringify(featureFlagValue)
      : featureFlagValue;

  const [currentValue, setCurrentValue] = useState<any>(formatFeatureFlagValue);

  if (typeof featureFlagValue === 'boolean') {
    return (
      <Checkbox
        label={featureFlagKey}
        name="checkbox"
        checked={currentValue === true}
        onChange={() => {
          setCurrentValue(!currentValue);
          onChange(featureFlagKey, !currentValue);
        }}
      />
    );
  }

  if (typeof featureFlagValue === 'string') {
    return (
      <div className="my-4">
        <TextInput
          label={featureFlagKey}
          name=""
          value={currentValue}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            onChange(featureFlagKey, e.target.value);
          }}
        />
      </div>
    );
  }

  if (typeof featureFlagValue === 'object') {
    return (
      <div className="my-4">
        <TextArea
          label={featureFlagKey}
          name=""
          value={currentValue}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            try {
              const alteredValue = JSON.parse(e.target.value);
              setCurrentValue(alteredValue);
              onChange(featureFlagKey, alteredValue);
            } catch (e) {
              console.error('Not valid for parsing yet');
            }
          }}
        />
      </div>
    );
  }

  return null;
}
