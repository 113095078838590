'use client';

import type { Context } from 'react';
import { createContext } from 'react';
import { FeatureFlags } from './feature-flags.types';

export const featureFlagsInitialValues: NonNullable<FeatureFlags> = {
  featureFlags: undefined,
  globalFeatureFlags: undefined,
};
export const FeatureFlagsContext: Context<NonNullable<FeatureFlags>> =
  createContext(featureFlagsInitialValues);
