'use client';

import type { ReactNode } from 'react';
import { PREVIEW_QUERY_PARAMS } from '@config';
import {
  FeatureFlagsContext,
  featureFlagsInitialValues,
} from '@contexts/feature-flags/feature-flags.context';
import { handleFeatureFlagsOverride } from '@utils/feature-flags/feature-flags-override';
import { useSearchParams } from 'next/navigation';
import { FeatureFlags } from './feature-flags.types';

export function FeatureFlagsProvider({
  children,
  featureFlags,
}: {
  children: ReactNode;
  featureFlags: FeatureFlags | null;
}) {
  const featureFlagsValues = !featureFlags
    ? featureFlagsInitialValues
    : featureFlags;

  const searchParams = useSearchParams();
  const overrideFeatureFlags = searchParams.get(
    PREVIEW_QUERY_PARAMS.overrideFlags,
  );

  const featureFlagsOverridden = handleFeatureFlagsOverride({
    featureFlags: featureFlagsValues,
    flagsToOverride: overrideFeatureFlags,
  });

  return (
    <FeatureFlagsContext.Provider value={featureFlagsOverridden}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
